@import "@fontsource/fontdiner-swanky";


@font-face {
    font-family: 'qdbettercomicsansboldbold';
    src: local(""), 
        url('./fonts/qdbettercomicsansbold-511d8-webfont.woff2') format('woff2'),
         url('./fonts/qdbettercomicsansbold-511d8-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

body {
    margin: 0;
    font-family: "Fontdiner Swanky", sans-serif;
    background-color: #03fd06;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000;
    font-size: 18px;
    overflow-x: hidden;
}

h1{
    font-size: 92px;
    margin-top: 0px;
    margin-bottom: 10px;
}

h2 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 40px;
}

h3 {
    margin-top: 0px;
    margin-bottom: 10px;
    font-weight: 300;
}

::-webkit-scrollbar {
    width: 20px;
    cursor:pointer;
  }
  
/* Track */
::-webkit-scrollbar-track {
    background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #fff;
    border: 3px solid #000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #444;
}

a,a:visited, button{
    outline: none;
    cursor: pointer;
}

@media all and (max-width: 720px) {
    h1 {
        font-size: 72px;
    }

    h2 {
        font-size: 26px;
    }
}

hr {
    border-bottom: 1px solid #222;
    margin: 100px auto;
}

.smallhr {
    border-bottom: 1px solid #333;
    border-top: #040404;
    border-left: none;
    border-right: none;
    margin: 50px auto;
}

.hidden{
    display: none !important;
}

.hidden2{
    display: none !important;
}

.show{
    display: block !important;
}


a:link,
a:visited,
a {
    color: #9e00ff;
    text-decoration: none;
    font-weight: bold;
}

a:hover {
    opacity: 0.5;
}

::-moz-selection {
    /* Code for Firefox */
    color: #030303;
    background: white;
}

::selection {
    color: #030303;
    background: white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

.Minter {
    padding: 80px 20px 100px;
    margin: 0 auto;
    text-align: left;
}

input {
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom: 3px solid #fff;
    font-weight: bold;
    padding-bottom: 5px;
    width: 100%;
    line-height: 32px;
    background: #030303;
    text-align: center;
    font-size: 32px;
    max-width: 60%;
    color: #fff;
}

input:focus {
    outline: none;
    border-bottom: rgba(255, 255, 255, 0.3) solid 3px;
    color: #fff;
}

button,
.button {
    background: white;
    color: #030303 !important;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 50px;
    align-items: center;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    font-family: "Open Sans", sans-serif;
    border: 3px solid rgba(0, 0, 0, 0);
    cursor:pointer;
}

button:disabled,
.button:disabled {
    opacity:0.2 !important;
}

.claimButton {
    background: #9e00ff;
    color: #030303 !important;
    font-size: 20px;
    padding: 10px 20px;
    border-radius: 50px;
    align-items: center;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    display:inline-block;
    border: 3px solid rgba(0, 0, 0, 0);
    cursor:pointer;
}

.claimButton:disabled {
    opacity:0.2 !important;
}


/* Actually the same as the other */
.smallbutton {
    background: white;
    color: #030303 !important;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 50px;
    align-items: center;
    font-weight: 500;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    font-family: "Open Sans", sans-serif;
    display: inline-block;
    border: 3px solid rgba(0, 0, 0, 0);
}

.buttonhover:not(:disabled):hover {
    background: #040404;
    border: 3px solid #fff;
    color: #fff !important;
    cursor: pointer;
}

.textcenter {
    text-align: center;
}

#title {
    padding-top: 32px;
}

#status {
    color: #fff;
}

#logo {
    position: fixed;
    top: 15px;
    left: 15px;
    z-index:10000;
}

#logo img {
    max-width: 200px;
}

.relative{
    position:relative;
}

.divider{
    height:100px;
}

.maintitle{
    text-align: center;
    position:relative;
    top: 200px;
    z-index: 1;
    padding-bottom:200px;
}

.loggedoutinfo {
    margin-top: 60px;
}

#walletButton {
    text-align: right;
    float: right;
    margin-bottom: 50px;
    line-height: 16px;
    font-size: 16px;
    position: fixed;
    top: 15px;
    right: 15px;
    padding: 12px 20px;
    border-radius: 50px;
    color: #030303;
    border: 3px solid white;
    cursor:pointer;
}

#mintButton {
    margin-top: 40px;
    color: #030303;
    background-color: #fff;
    border: 3px solid white;
    font-size: 15px;
    border-radius: 50px;
    padding: 15px 30px;
    transition: all 0.3s ease;
}

#mintButton:hover {
    color: #fff !important;
    background-color: #030303;
    border: #fff solid 3px;
}

.hero {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
}

#circles {
    margin: auto;
    overflow: visible;
    max-width: 800px;
    display: block;
}

#circles .st0 {
    margin: auto;
    display: block;
    stroke: #fff;
    stroke-width: 90;
    width: 100%;
    height: 100%;
}

@keyframes dash {
    to {
        opacity: 1;
    }
}

@keyframes dash2 {
    to {
        opacity: 1;
    }
}

.golemcontainer {
    margin: auto;
    max-width: 800px;
    padding: 20px;
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    text-align: center;
}

.errorline {
    height: 32px;
    background: #fff;
    border-bottom: 2px solid #030303;
    width: 100%;
}

.footer {
    text-align: center;
}

.finished {
    text-align: center;
    margin: 250px 0;
}

.loader {
    position:relative;
}
.loader span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 40px;
    width: 40px;
}
.loader span::before,
.loader span::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 40px;
    width: 40px;
    border: 3px solid #fff;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: loader-1 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
    animation: loader-1 2s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}
@-webkit-keyframes loader-1 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1.5);
        opacity: 0;
    }
}
@keyframes loader-1 {
    0% {
        transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 0, 0) scale(1.5);
        opacity: 0;
    }
}
.loader-6 span::after {
    -webkit-animation: loader-2 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s
        infinite;
    animation: loader-2 2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s infinite;
}
@-webkit-keyframes loader-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1);
        opacity: 0;
    }
}
@keyframes loader-2 {
    0% {
        transform: translate3d(0, 0, 0) scale(0);
        opacity: 1;
    }
    100% {
        transform: translate3d(0, 0, 0) scale(1);
        opacity: 0;
    }
}

.fadeIn {
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 2s; /* Firefox < 16 */
    -ms-animation: fadein 2s; /* Internet Explorer */
    -o-animation: fadein 2s; /* Opera < 12.1 */
    animation: fadein 2s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeOut {
    -webkit-animation: fadeout 2s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadeout 2s; /* Firefox < 16 */
    -ms-animation: fadeout 2s; /* Internet Explorer */
    -o-animation: fadeout 2s; /* Opera < 12.1 */
    animation: fadeout 2s;
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.fadeInFast {
    -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 0.5s; /* Firefox < 16 */
    -ms-animation: fadein 0.5s; /* Internet Explorer */
    -o-animation: fadein 0.5s; /* Opera < 12.1 */
    animation: fadein 0.5s;
}


/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media all and (max-width: 500) {
    .center {
        display: block;
        margin: 30px auto;
        position: relative;
    }
}

.center span {
    left: calc(50% - 24px);
}

.moveTop.center {
    padding-top: 10px;
    padding-bottom: 200px;
    position: relative;
    width: 100%;
}

.moveTop.center span {
    left: 0;
    width: 100%;
    margin: 30px auto;
}


.wrapcountdown {
    padding-top: 47%;
    min-height: 600px;
    text-align: center;
}

.countdown {
    text-align: center;
    font-size: 44px;
    line-height: 56px;
    font-weight: bold;
}

#scountdown {
    height: 150px;
    opacity: 0;
    animation-delay: 1s;
}


.p50 {
    max-width: calc(50% - 60px);
    max-height: 300px;
    display: inline-block;
    padding: 30px;
}

.p80 {
    max-width: calc(80%);
    padding: 30px;
    margin: auto;
    display: block;
}

@media all and (max-width: 640px) {
    .p80 {
        max-width: calc(100%);
        padding: 0;
    }

    .p50 {
        max-width: calc(100% - 60px);
        max-height: 300px;
        display: inline-block;
        padding: 30px;
        margin-bottom: 15px;
    }
}

.smallfont {
    font-size: 14px;
    line-height: 18px;
}

.verysmallfont {
    font-size: 12px !important;
    line-height: 12px !important;
}

.red{
    color: red !important;
}

.green{
    color:#03fd06 !important;
}

.orange{
    color: #e67853 !important;
}

.greyfont, .grey{
    color: #999;
}

.clearfix {
    clear: both;
}


.left{
    float:left;
}

.right{
    float:right;
}

.centertext{
    text-align:center;
}

.loginerror{
    z-index:100;
    padding:15px;
    background: red;
    border-radius: 5px;
    color: #fff;
}

.comingsoon{
    height:50px;
    padding-top:15px;
}



.rumble {
    -webkit-animation: rumble .0625s infinite linear;
}


@-webkit-keyframes rumble {
    0% {-webkit-transform: rotate(0deg);}
    25% {-webkit-transform: translate(3px, 0px) rotate(-3deg);}
    50% {-webkit-transform: translate(0px, -3px) rotate(0deg);}
    75% {-webkit-transform: translate(-3px, 0px) rotate(3deg);}
    100% {-webkit-transform: translate(0px, 3px) rotate(0deg);} 
}

@keyframes rumble {
    0% {transform: rotate(0deg);}
    25% {transform: translate(3px, 0px) rotate(-3deg);}
    50% {transform: translate(0px, -3px) rotate(0deg);}
    75% {transform: translate(-3px, 0px) rotate(3deg);}
    100% {transform: translate(0px, 3px) rotate(0deg);} 
}

.claimButton:active{
    -webkit-animation: rumble .0625s infinite linear;
    animation: rumble .0625s infinite linear;
}

.tiltleft{
    transform: rotate(-5deg);
    position:relative;
    left:-50px;
}

.tiltright{
    transform: rotate(5deg);
    position:relative;
    right:-50px;
}

@media all and (max-width:640px)
{
    .tiltleft{
        left:-10px;
    }
    
    .tiltright{
        right:-10px;
    }
}

.textleft{
    text-align:left;
}
.w640{
    max-width:450px;
    margin:auto;
    padding:15px;
}

.maintitle{
    left:0;
    right:0;
    top:0;
    bottom:0;
    width:100%;
    height:100%;
}

.maintitle h2{
    padding-top:150px;
}

.maintitle h1{
    top: -20px;
    position:relative;
}

.nohover:hover{
    opacity: 1;
}

.golems{
    z-index:10;
    position:fixed;
    bottom: 0;
}

.g{
    position:fixed;
    bottom:-15px;
    width:15%;
}

.g1{
    bottom:-15px;
    left:0;
    width:13%;
}

.g2{
    bottom:-15px;
    left:11%;
}

.g3{
    bottom:-15px;
    left:22%;
    z-index:5;
    width:16%;
}
.g4{
    bottom:-15px;
    left:35%;
    z-index:2;
}

.g5{
    bottom:-15px;
    left:48%;
    z-index:3;
    width:18%;
}

.g6{
    bottom:-15px;
    left:65%;
    z-index:2;
}

.g7{
    bottom:-15px;
    right:0;
    width:18%;
}

@media all and (max-width:960px)
{
    .g{
        width:22%;
    }
}

@media all and (max-width:720px)
{
    .g{
        width:25%;
    }
}

.whomadezegolemz{
    position:fixed;
    bottom:5px;
    left:0;
    right:0;
    font-size:8px;
    font-family: 'Courier New', Courier, monospace;
    z-index:100;
    text-align: center;
    padding:3px;
    color:#fff;
}

.goopapertext{
    background: #faffe7;
    padding: 40px;
    text-align:left;
    position:relative;
    border-top-left-radius: 250px 15px;
    border-bottom-left-radius: 10px 80px;
    border-bottom-right-radius: 105px 15px;
}

.goopapertext::before,
.goopapertext::after {
  content: '';
  position: absolute;
  bottom: 20px;
  width: 40%;
  height: 10px;
  box-shadow: 0 5px 14px rgba(0,0,0,.7);
  z-index: -1;
  transition: all .3s ease-in-out;
}

.goopapertext::before {
  left: 15px;
  transform: skew(-5deg) rotate(-5deg);
}

.goopapertext::after {
  right: 15px;
  transform: skew(5deg) rotate(5deg);
}

.normalfont{
    font-family: Arial, Helvetica, sans-serif;
}

.y2044{
    position: relative;
    bottom:-50px; 
    z-index:0;
    max-width:100%;
}

.bydonatello{
    position: fixed;
    top: 15px;
    left:15px; 
    width:100px;
    height:100px;
    border-radius: 50%;
    overflow:hidden;
    border: 3px solid #000;
    z-index:100;
}

.bydonatello a{
    width:100%;
    height:100%;
    display:block;
}

.bydonatello img{
    width: 100%;
}

.bydonatello #golemgm{
    position: fixed;
    top: 45px;
    left:135px; 
    width:120px;
    overflow:hidden;
    z-index:100;
    display:none;
}

.riddleinput{
    font-size:50px;
    margin-bottom:50px;
    margin-top:30px;
    font-family: 'Courier New', Courier, monospace !important;
    font-weight: bold;
}

.riddlebutton{
    background: #000;
    color: #fff !important;
    font-size:50px;
    padding: 15px 50px;
    display:inline-block;
    font-family: 'Courier New', Courier, monospace !important;
    font-weight: bold;
}    

.riddleresult{
    margin-top:50px;
    max-height:600px;
    max-width:90%;
}


/* WALLET */
.currentwallet{
    position:fixed;
    top: -3px;
    right: -3px;
    font-family: "qdbettercomicsansboldbold","Comic Sans MS", "Comic Sans", cursive;
    font-weight: bold;
    background: #fff;
    padding: 10px 10px 20px 20px;
    border: 3px solid #000;
    border-radius: 30% 0 30% 20%;
}

.walletAddress{
    display:block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width:150px;
}

