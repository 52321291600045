/* POOL CLUB */

.poolclub{
    background: #c402f9;
    position: fixed;
    width:100%;
    bottom:0;
    top:0;
}

.poolclub h2{
    margin: 5px 0;
}

.specialaction{
    background:#00cf03;
    color: #000 !important;
    font-size:22px;
    text-align:center;
    padding:5px;
    margin:15px auto;
    position:relative;
    bottom:0px;
    width:500px;
    border-radius:20px;
}

.specialaction a {
    color: #000 !important;
}

.specialaction:hover{
    cursor: pointer;
    background:#fefe4e;
}

.wrappoolclub .specialaction {

}

.wrappoolclub{
    color: #fff;
    text-align:center;
    position:relative;
    margin:auto;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
}

.nowrappoolclublarge{
    display: none;
}

@media all and (max-width: 820px)
{
    .wrappoolclublarge {
        display:none;
    }

    .nowrappoolclublarge{
        display:block;
    }
}

.golemhand{
    cursor: url('cursor.png'), auto !important;
}

.leavebutton{
    display: inline-block;
    font-size:14px;
    padding: 5px 15px;
    margin-bottom:15px;
}
.leavebuttononlarge{
    position:absolute;
    top: 5px;
    right: 5px;
    max-width:90px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.leavebuttonblack{
    display: inline-block;
    font-size:14px;
    padding: 5px 15px;
    margin-bottom:25px;
    background: #000;
    color: #fff !important;
}

.leavebutton:hover{
    opacity: 0.7;
}

.navigation{
    position:absolute;
    z-index:99;
    background: #000;
    padding:1px;
}

.navigation a{
    color: #00cf03;
}

.navigation:hover{
   background: #00cf03;
   cursor:pointer;
}

.navigation:hover a{
    color: #000;
    opacity: 1;
}

.navtop{    
    top:0;
    width:100px;
    left:50%;
    border-radius:0 0 10px 10px;
    transform: translateX(-50%);    
}

.navbottom{    
    bottom:0px;
    width:100px;
    left:50%;
    border-radius:10px 10px 0px 0px;
    transform: translateX(-50%);    
}

.navright{    
    top:50%;
    width:30px;
    padding-top:35px;
    height:65px;
    right:0;
    border-radius:10px 0px 0px 10px;
    transform: translateY(-50%);    
}

.navleft{    
    top:50%;
    width:30px;
    padding-top:35px;
    height:65px;
    left:0;
    border-radius:0px 10px 10px 0px;
    transform: translateY(-50%);    
}

.navigation img{
    max-height:24px;
    max-width:24px;
}



.poolclubzone{
    max-height: calc(100vh - 350px);
    aspect-ratio: 1 / 1;
    overflow: hidden;
    border: 5px solid #000;
    margin:auto;
    position:relative;
    min-height:320px;
    cursor:pointer;
    border-radius:10px;
}

.poolclubzonelarge{
    max-height: calc(100vh - 250px);
    aspect-ratio: 16 / 8;
    overflow: hidden;
    border: 5px solid #000;
    margin:15px auto;
    position:relative;
    min-height:360px;
    cursor:pointer;
    border-radius:10px;
}

.wrappoolclub .bg{
    width:100%;    
    margin: 0;
    padding:0;
}

.wrappoolclub img:hover{}

.wrappoolclub .bruv{
    position: absolute;
    left:22%;
    top:54%;
    width:24%;
    -webkit-animation: bruva 4s ease-in-out infinite;
    animation: bruva 4s ease-in-out infinite;
}

.poolclubzonelarge .bruv{
    left:40%;
    top:58%;
    width:10%;
}

.wrappoolclub .derp{
    right:20px;
    top:58%;
    position: absolute;
    width:25%;
    -webkit-animation: derpa 3s ease-in-out infinite;
    animation: derpa 3s ease-in-out infinite;
}

.wrappoolclub .babo{
    right:25%;
    top:58%;
    position: absolute;
    width:12%;
    -webkit-animation: baboa 3s ease-in-out infinite;
    animation: baboa 3s ease-in-out infinite;
}


@keyframes derpa {
    0% {
        margin-top:-4px;
    }
    50% {
        margin-top:0px;
    }
    100% {
        margin-top:-4px;
    }
}

@keyframes bruva {
    0% {
        margin-top:-5px;
    }
    50% {
        margin-top:0px;
    }
    100% {
        margin-top:-5px;
    }
}

@keyframes baboa {
    0% {
        margin-top:-5px;
    }
    50% {
        margin-top:0px;
    }
    100% {
        margin-top:-5px;
    }
}

.missing{
    position:absolute;
    width:30px;
    height:30px;
    left:24%;
    top:39%;
}

.cocktail{
    position:absolute;
    width:40px;
    height:140px;
    left:0;
    top:52%;
}

.speechbubble{
    font-family: "qdbettercomicsansboldbold","Comic Sans MS", "Comic Sans", cursive;
    color: #000;
    position:absolute;
    font-size:16px;
    font-weight: bold;
    background-size: cover;
}

.speechbubble-derp{
    width: 45%;
    max-width:250px;
    padding-top: 6%;
	padding-bottom: 20%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    top: 24%;
    right: 10%;
    display:none;
    padding-left: 10px;
    padding-right: 10px;
}

.speechbubble-derp-text{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.speechbubble-bruv{
    width: 45%;
    max-width:250px;
    padding-top: 6%;
	padding-bottom: 20%;
    top: 15%;
    left: 25%;
    display:none;
    padding-left: 10px;
    padding-right: 10px;
}

.speechbubble-bruv-3{
    width: 40%;
    max-width:250px;
    padding-top: 3%;
	padding-bottom: 10%;
    top: 15%;
    left: 45%;
    display:none;
    font-size:22px;
    padding-left: 10px;
    padding-right: 10px;
}


.menuzone{
    position:fixed;
    bottom:0;
    left:0; 
    right: 0;
    height: 140px;
    background: #000;
    z-index:1000;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.wrapmenuzone{
    max-width:960px;
    position:relative;
    height: 120px;
    margin: auto;    
}

.menuzone .effectfield{
    position:absolute;
    top:-20px;
    text-align:center;
    width:100%;
    color: #fff;
    z-index: 102;
    opacity: 0;
}

.menuzone .effectfield.active{
    animation: effecttransition 3s linear;
}

.menuzone .effectfield.success{
    animation: effecttransitionSuccess 3s linear;
}

@keyframes effecttransition {
    0% {
        opacity: 1;
        top:-20px;
    }
    85% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        top:-40px;
    }
}

@keyframes effecttransitionSuccess {
    0% {
        opacity: 1;
        top:-20px;
    }
    85% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
        top:-40px;
    }
}

.menuzone .select{
    padding: 5px 0;
    color:#03fd06; 
    height:20px;
    text-transform:uppercase;
    text-align:center;
}

.menuzone .left{
    float:left;
    width:40%;
    height: 100%;;
    background: #000;
    font-size:14px;
}

.menuzone .left .b{
    float:left;
    color: #fff;
    text-align:center; 
    width: calc(33% - 3px);
    background:#111;
    height: 25px;
    line-height:25px; 
    padding:5px 0;
    box-sizing: content-box;
    border-top: 1px solid #000;
    border-right: 1px solid #222;
    border-bottom: 2px solid #222;
    border-left: 2px solid #000;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-radius:3px;

}

.menuzone .left .b.active{
    background: #03fd06;
    color: #000;
}


.menuzone .left .b:hover{
    opacity:0.5;
    cursor:pointer;
}

.menuzone .left .b:active{
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    transform: scale(0.9);
}

.menuzone .right{
    float:left;
    width:calc(66% - 290px);
    height:100%;
    background:#000000;
}

.menuzone .right .b{
    float:left;
    width: 50px;
    background:#111;
    height: 50px;
    line-height:50px; 
    box-sizing: content-box;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-top: 1px solid #000;
    border-right: 1px solid #222;
    border-bottom: 2px solid #222;
    border-left: 2px solid #000;    
}

.menuzone .right .b.active{
    background:#03fd06;
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;   
}

.menuzone .right .b.active img{
    opacity:0.3;
}

.menuzone .right .b img{
    width:100%;
    margin: 0;
    padding:0;
}

.menuzone .right .b:hover{
    opacity:0.5;
    cursor:pointer;
}

.menuzone .right .b:active{
    border-top: 1px solid #000;
    border-right: 1px solid #000;
    border-bottom: 2px solid #000;
    border-left: 2px solid #000;
    transform: scale(0.9);
}

.wrapItems{
    height:140px;
    overflow-y:scroll;
}

.wrapItems::-webkit-scrollbar {
    width: 12px;
    cursor:pointer;
  }
  
/* Track */
.wrapItems::-webkit-scrollbar-track {
    background: #000;
}

/* Handle */
.wrapItems::-webkit-scrollbar-thumb {
    background: #fff;
    border: 3px solid #000;
}

/* Handle on hover */
.wrapItems::-webkit-scrollbar-thumb:hover {
    background: #444;
}

.menuzone .inventoryicon{
    width:100px;
    height:100px;;
    float:left;
    margin-left:10px;
    text-align:center;
    opacity:0.15;
}

.menuzone .inventoryicon img{
    max-width:100%;
    max-height: 100%;
}

.menuzone .inventoryicon:hover{

    /*opacity:0.7;
    transform: scale(1.1) rotate(5deg);*/

}

.menuzone .inventoryicon:active{
    /*animation: wobble 0.1s ease infinite;*/
}

@keyframes wobble {
    0% {
        transform: rotate(10deg);
    }
    50% {
        transform: rotate(-10deg);
    }
    100% {
        transform: rotate(10deg);
    }
}

.menuzone .charactericon{
    width:100px;
    margin:0px 20px 20px 0;
    float:left;
    text-align:center;
}

.menuzone .charactericon img{
    max-width:100%;
    max-height: 100%;
    width:64px;
    height:64px;
    border:4px solid #fff;
    border-radius: 50%;
    overflow:hidden;
    box-sizing: border-box;
}

.menuzone .charactericon:hover img{
    cursor:pointer;
    opacity: 0.5;
}

.menuzone .charactericon:active{
    transform: scale(0.9);;
}

.menuzone .charactername{
    color:#00cf03;
    font-size:12px;
    margin-top: 5px;
    max-width:100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* INVENTORY AND CHARACTER POPUPS */
.wrappopup{
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background:rgba(0,0,0,0.8);
    z-index:100000;
}

.popup{
    max-width:960px;
    width:70%;
    position:absolute;
    top:5%;
    left: 50%;
    transform: translateX(-50%);
    z-index:100001;
    border: 3px solid #00cf03;
    border-radius: 15px;

}

.scrollGolemz{
    padding:15px;
    max-height: 60vh;
    overflow-y:auto;
    overflow-x: hidden;
}

.golemzSelectionPopup{
    padding: 15px;
    background: #000;
    color:#00cf03;
    text-align:center;
    min-height:300px;
    border-radius: 15px;

}

@media all and (max-width:1280px)
{
    .popup{
        width:90%;
    }
}



.closebutton{
    background: #00cf03;
    color: #000;
    display: inline-block;
    padding: 10px 25px;
    border-radius:25px;
    border: 3px solid #00cf03;
}

.closebutton:hover{
    background: #000;
    color: #00cf03;
    border: 3px solid #00cf03;
    cursor: pointer;
}

.golemzList{
    margin: 30px 0;
}

.golemPortrait{
    max-width:calc(25% - 36px);
    min-height:120px;
    padding: 15px;
    float:left;
    cursor:pointer;
    border: 3px solid rgba(0,0,0,0);
}

.golemPortrait.selected{
    border: 3px solid #00cf03;
    border-radius: 15px;
}

.golemPortrait img{
    max-width:100%;
    border-radius: 15px;

}

.golemPortrait:hover{
    transform: scale(1.1);
}


.wrappoollogo{
    padding:25px 50px;
    background: #c402f9;
    color: #fff;
    border-radius: 40px;
    display: inline-block;
}

.poolclublogo{
    max-width:350px;

}

.wrappoollogo{
    position:relative;
    overflow: hidden;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.4);
    margin-top:15px;
    transition: box-shadow 0.3s ease-in-out;
}

.wrappoollogo:hover{
    transform: scale(1.2);
    animation: TransitioningBackground 6s ease infinite;
    transition: 0.3s;
    box-shadow: 0px 0px 25px 15px rgba(0,0,0,0.1);
}

@keyframes TransitioningBackground {
    0% {
      background-position: 1% 0%;
    }
    50% {
      background-position: 99% 100%;
    }
    100% {
      background-position: 1% 0%;
    }
  }

  .wrappoollogo::before {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 60px;
    height: 100%;
    top: 0;
    filter: blur(30px);
    transform: translateX(-100px) skewX(-15deg);
  }

  .wrappoollogo::after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 30px;
    height: 100%;
    top: 0;
    filter: blur(5px);
    transform: translateX(-100px) skewX(-15deg);
  }

  .wrappoollogo:hover {
    background-image: (linear-gradient(to left, #2d8fe5, #d155b8));
    transform: scale(1.2);
    cursor: pointer;
  }

.wrappoollogo:hover::before,
.wrappoollogo:hover::after {
    transform: translateX(300px) skewX(-15deg);
    transition: 0.7s;
}

.golemzamount{

}

.missinggolem{
    max-width: 520px;
    margin: auto;
    display:inline-block;
}

.wrapmissinggolem{
    text-align:center;
    margin-top:25px;
}

/* A GUD STORY */

.agudstory{
    position:fixed;
    bottom:25px;
    right:55px;
    z-index: 10;
    width:150px;
}

.agudstory img{
    width:100%;
}

.agudstory:hover{
    transform: scale(1.1);
}

@media all and (max-width:720px)
{
    .agudstory{
        width:110px;
        right:25px;
    }
}
.agudstoryc{
    background: #fefe4e;

}

.wrapagudstory{
    text-align:center;
    padding-bottom: 52px;
}

.agudchapter{
    max-width:900px;
    margin:auto;
    position:relative;

}

.agudchapterinside{
    background:#fff;
    z-index: 10;
    position:relative;
    padding:10px 10px;
    border-top-right-radius: 15px 80px;
    border-top-left-radius: 250px 15px;
    border-bottom-left-radius: 10px 80px;
    border-bottom-right-radius: 105px 12px;
    margin-bottom:50px;
}

.agudchapter::before,
.agudchapter::after {
  content: '';
  position: absolute;
  bottom: 27px;
  width: 40%;
  height: 10px;
  box-shadow: 10px 5px 14px rgba(0,0,0,.7);
  z-index: 1;
  transition: all .3s ease-in-out;
  
}

.agudchapter::before {
  left: 15px;
  transform: skew(-5deg) rotate(-5deg);
}

.agudchapter::after {
  right: 15px;
  transform: skew(5deg) rotate(5deg);
}

.agudimgsmall{
    width:calc(50% - 40px);
    padding:10px 10px 0px 10px;
}

.agudimg{
    padding:10px 20px 10px 20px;
    width:calc(100% - 60px);
}

.pagenumber{
    text-align:right;
    font-size:11px;
    padding-right:40px;
    padding-bottom:10px;
    padding-top:10px;
    position: relative;
}

.footnote{
    float:left;
    text-align:left;   
    padding-left:40px;
}

.activation{
    background: yellow;
    border:3px solid #000;
    padding:5px;
    margin: 5px 0 5px;
}

.wrapsummary{
    margin:auto;
    color: #fff;
    border-radius:50px;
    background: #000;
    max-width:720px;
    padding:50px;
    width: 90%;
}

.summaryitem{
    max-width: 280px;
    margin-bottom:10px;
}

.summaryitemline{
    margin-bottom:30px;
    font-family: "Comic Sans MS", "Comic Sans", cursive;
}

.summaryblock{
    float:left;
    padding: 15px 25px;
    width:calc(33% - 50px);
    display: block;
    text-align: center;
    height: 300px;
}

.summaryblock img{
   width:100%;
   max-width:240px;
}

@media all and (max-width:800px)
{
    .wrapsummary{
        max-width: calc(90% - 50px);
        padding:25px;
    }

    .summaryblock{
        padding: 15px 25px;
        width:calc(50% - 50px);
    }
}

.playMusic{
    max-width:120px;
    width:20%;
    position:absolute;
    bottom:10px;
    left:20%;
}

.playMusic img{
    max-width:100%;
}

.poolclubzonelarge .playMusic{
    left:40%;
    bottom:10px;
    width:8%;
}

@media all and (max-width:1280px)
{
    .poolclubzonelarge .playMusic{

    }

}

.talkingchar{
    position:absolute;
    bottom: -1px;
    max-width:60%;
    max-height: 60%;;
    z-index:101;
    -webkit-animation: talkingCharAni 2.5s ease-in-out infinite;
    animation: talkingCharAni 2.5s ease-in-out infinite;
}

.talkingcharLeft{
    left:10px;
}

.talkingcharRight{
    right:10px;
}

@keyframes talkingCharAni {
    0% {
        margin-bottom: -1px;
    }
    50% {
        margin-bottom: -6px;
    }
    100% {
        margin-bottom: -1px;
    }
}

.talkingchar.happy{

}

.talkingchar.unhappy{

}

.flipchar{
    transform: scaleX(-1);
}

.wrappoolclublarge .talkingcharLeft{
    left:5%;
}

.wrappoolclublarge .talkingcharRight{
    right:5%;
}


.wraptextBubble{
    background: #fff;
    padding:15px;
    color: #000;
    font-size:24px;
    position:absolute;
    top:0;
    left:0;
    right:0;
    z-index:500;
    filter:  /* the more shadow you add the thicker the border will be */
        drop-shadow(0px 0px 0.7px black) 
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black)
        drop-shadow(0px 0px 0.7px black);
    
}

.wraptextBubbleLeft:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 30%;
    height: 40px;
    width: 90px;
    background: inherit;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.wraptextBubbleRight:before {
    content: "";
    position: absolute;
    top: 100%;
    right: 30%;
    height: 40px;
    width: 90px;
    background: inherit;
    clip-path: polygon(0 0, 100% 0, 50% 100%);
}

.wraptextBubble.hidden{
    display:none;
}

.wraptextBubble .text{
    margin-bottom:5px;
}


.choicebutton{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;;
    padding:10px 0;
    color:#00cf03;
    font-weight:bold;
    border-radius: 5px;
    font-size:18px;
}

.choicebutton:hover{
    background: #f4e8e3;
}



@media all and (max-width:1440px)
{
    .wraptextBubble, .choicebutton{
        font-size:18px;
    }

    .wraptextBubble{
        padding:5px;
        font-size:18px;
    }

    .wraptextBubbleLeft:before {
        height: 40px;
        width: 80px;
    }

    .wraptextBubbleRight:before {
        height: 40px;
        width: 80px;
    }
}

@media all and (max-height:800px)
{
    .choicebutton{
        font-size:13px;
    }

    .wraptextBubble{
        padding:5px;
        font-size:15px;
    }
}

.blurout3{
    -webkit-animation: blurout 3s infinite;
    animation: blurout 3s infinite;    
}

@keyframes blurout {
    0% { 
        filter: blur(0px);
        -webkit-filter: blur(0px); 
        opacity: 1;
    }
    100% { 
        filter: blur(100px);
        -webkit-filter: blur(100px); 
        opacity: 0;
    }
}


.blurinroom{
    -webkit-animation: blurin 2s linear;
    animation: blurin 2s linear;    
}


@keyframes blurin {
    0% { 
        filter: blur(100px);
        -webkit-filter: blur(100px); 
        opacity:0;
    }
    100% { 
        filter: blur(0px);
        -webkit-filter: blur(0px); 
        opacity:1;
    }
}


.movecharoutbottom{
    -webkit-animation: movecharoutbottom 3s forwards;
    animation: movecharoutbottom 3s forwards;
}


@keyframes movecharoutbottom {
    0% { 
        bottom: 0;
    }
    100% { 
        bottom: -500px;
    }
}

.movecharinfrombottom{
    -webkit-animation: movecharinfrombottom 3s forwards;
    animation: movecharinfrombottom 3s forwards;
}


@keyframes movecharinfrombottom {
    0% { 
        bottom: -500px;        
    }

    100% { 
        bottom: 0;
    }
}

.fadein{
    -webkit-animation: fadein 2s forwards;
    animation: fadein 2s forwards;
}

@keyframes fadein {
    0% { 
        opacity:0;
        display:block;      
    }

    100% { 
        opacity:1;
        display:block;    
    }
}

.schrubschrub{
    -webkit-animation: schrubschrub 0.2s infinite !important;
    animation: schrubschrub 0.2s infinite !important;
}

@keyframes schrubschrub {
    0% { 
        transform: scale(1.1) rotate(5deg); 
    }

    50% { 
        transform: scale(1) rotate(-5deg);   
    }

    100% { 
        transform: scale(1.1) rotate(5deg);   
    }
}

.soundeffect{
    position:absolute;
    font-family: "qdbettercomicsansboldbold","Comic Sans MS", "Comic Sans", cursive;
    color: #fff;

}

.soundeffect-schrub{
    top: 51%;
    left: 38%;
    display: none;
    transform: rotate(-15deg);
}

.clickBox{
    position:absolute;
    left:0;
    bottom:0;
    width:12%;
    height:42%;
}

.clickKetschup{
    position:absolute;
    right:0;
    bottom:0;
    width:7%;
    height:30%;
}

.speechbubble-bruv-4{
    width: 40%;
    max-width:250px;
    padding-top: 3%;
	padding-bottom: 10%;
    top: 15%;
    left: 45%;
    font-size:22px;
    display: none;
    padding-left: 10px;
    padding-right: 10px;
}

.speechbubble-bruv-5{
    width: 40%;
    max-width:250px;
    padding-top: 3%;
	padding-bottom: 10%;
    top: 12%;
    left: 45%;
    font-size:20px;
    display: none;
    padding-left: 10px;
    padding-right: 10px;
}

.privateArea{
    position:absolute;
    left:45%;
    top:35%;
    width:7%;
    height:22%;
}

/* SHOWER */

.showerhead{
    width:120px;
    height:120px;
    left:48%;
    top:20%;
    position:absolute;
}

.zegudketschupshower{
    width:80px;
    height:80px;
    left:48%;
    top:40%;
    position:absolute;
}

.computerWAA{
    position:absolute;
    bottom: -30px;
    right: -30px;
    width:35%;
    transform: scale(0.9);
}

.computerWAA img{
    width: 100%;
}
.computerWAA:hover{
    transform: scale(1);
}

.computeroverlay{
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    left:0;
    background:#00cf03;
    border:10px solid #b0c3d3;
    font-family:'Courier New', Courier, monospace;
    font-weight: bold;
    display:none;
    z-index:100;
    
}

.computeroverlay .step{
    padding:30px 30px 30px 30px;
    overflow-y:auto;
    position:relative;
    height: calc(100% - 100px);
    top:0;
    right:0;
    left:0;
    display: none;
}

.gobackbutton{
    position:absolute;
    bottom:0;
    right:0;
    left:0;
    padding:15px;
    background: #ddd;
    color:#000;
    font-weight:bold;
}

.gobackbutton:hover{
    background: #111;
    color:#fff;
}

.computerbutton{
    border-radius: 0;
    background:#005701;
    color: #fff;
    display:inline-block;
    padding:15px 20px;
    font-weight: bold;;
}

.computerbutton:hover{
    background:#111;
}

.computeroverlay h1{
    font-size: 60px;
}

@media all and (max-width:1440px)
{
    .computeroverlay h1{
        font-size:24px;
    }

    .computeroverlay h2{
        font-size:18px;
    }

    .computeroverlay{
        font-size:14px;
    }

}

@media all and (max-height:800px)
{
    .computeroverlay h1{
        font-size:24px;
    }

    .computeroverlay h2{
        font-size:18px;
    }

    .computeroverlay{
        font-size:14px;
    }
}



.grow{
    -webkit-animation: growa 3s ease-in-out none;
    animation: growa 3s ease-in-out none;
}


@keyframes growa {
0% {
    transform: scale(1);
}
100% {
    transform: scale(1.6);
}
}


.golemzPortraitPixel{
    margin:30px;
    text-align:center;
    filter: sepia(0.80);
    border-radius: 15px;
}

.golemzPortraitPixel img{
    max-width:200px;

}

.setWaInput{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
}

.showerfog{
    position:absolute;
    left:25%;
    top:30%;
    max-width:35%;
    z-index:10;
}

.goleminshower{
    z-index:9;
    position:absolute;
    left:36%;
    top:40%;
    max-width:13%;
    -webkit-animation: showergolem 5s linear infinite;
    animation: showergolem 5s linear infinite;
}


@keyframes showergolem {
    0% {
        transform: scaleX(1);
    }
    49.9999%{
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(-1);
    }
    99.999%{
        transform: scaleX(-1);
    }
    100% {
        transform: scaleX(1);
    }
}

.goleminshowerwa{
    z-index:11;
    position:absolute;
    top:20%;

    font-family: "qdbettercomicsansboldbold","Comic Sans MS", "Comic Sans", cursive;
    -webkit-animation: goleminshowerwaa 6s linear infinite;
    animation: goleminshowerwaa 6s linear infinite;

}

@keyframes goleminshowerwaa {
    0% {
        transform: rotate(5deg);
        left:45%;
    }
    24.9999%{
        transform: rotate(5deg); 
        left:45%;
    }
    25% {
        transform: rotate(-10deg);
        left:35%;
    }
    54.9999%{
        transform: rotate(-10deg);
        left:35%;
    }
    55% {
        transform: rotate(-1deg);
        left:40%;
    }
    74.9999%{
        transform: rotate(-1deg);
        left:40%;
    }
    75% {
        transform: rotate(-3deg);
        left:30%;
    }
    99.999%{
        transform: rotate(-3deg);
        left:30%;
    }
    100% {
        transform: rotate(5deg);
        left:45%;
    }
}

.wrapbiggolem{
    position:absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top:0;
    background: #00cf03;
    z-index:10000;
}
    
.biggolem{
    position:absolute;
    height: 110%;
    left:calc(50% - 20px);
    bottom:0px;
    top:0;
    border-left:20px solid #000;
    transform: rotate( 5deg);
}

.biggolemname{
    position:absolute;
    width:calc(50% - 100px);
    left:0;
    top:0;
    bottom:0;
    padding: 150px 50px 50px 50px;
    color: #000;
    overflow: hidden;
    width: 40%;
    white-space: nowrap;

}

.biggolemname h1{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

@media all and (max-width: 1700px)
{
    .biggolemname h1{
        font-size:50px;
        
    }

}

@media all and (max-width: 1600px)
{
    .biggolemname{
        padding: 100px 50px 50px 50px;
    }

    .biggolemname h1{
        font-size:40px;

        width:100%;
    }

    .biggolemname h2{
        font-size:26px;
    }
}

.sharebutton{
    background: #000;
    padding: 15px 30px;
    font-size:20px;
    color: #fff;
    display: inline-block;
    border-radius: 15px;
}

.sharebutton:hover{
    background: #fff;
    color: #000;
}


/* CAFETERIA */

.guestbookamount{

    position: absolute;
    top:49%;
    width:5%;
    font-size:36px;
    color: #fff;
    left:17.5%;
    z-index:15;
    padding: 2% 1%;

}

@media all and (max-width:1080px)
{
    .guestbookamount{
        font-size:24px;
    }
}



.guestbook{
    position:absolute;
    top:40px;
    left:0;
    right:10%;
    background: #fff;
    border-right:#000 solid 30px;
    border-top: #000 solid 20px;
    bottom:0;
    z-index: 100;
    border-radius: 3px;
    transform: rotate(-0.5deg) scale(1.05);
    transition: top 1s ease-in-out;
    color: #000;
    box-shadow: 0px 50px 70px 80px rgba(0,0,0,0.7);
    
}

.guestbookhidden{
    top:115%;
}


.guestbookright{
    position:relative;
    width:calc(90% - 20px);
    left:10%;
    border-left:20px solid #000;
    height:100%;
    top:-5px;
    border-right: 3px solid #333;
    background: #fff;
    z-index:100;
}

.guestbookpage1{
    position:absolute;
    top: 0; 
    left:10%;
    right:-7px;
    background: #fff;
    border-right:2px solid #333;
    bottom:0;
    z-index: 99;
}
.guestbookpage2{
    position:absolute;
    top: 2px; 
    left:10%;
    right:-11px;
    background: #fff;
    border-right:2px solid #333;
    bottom:0;
    z-index: 98;
}
.guestbookpage3{
    position:absolute;
    top: 4px; 
    left:10%;
    right:-14px;
    background: #fff;
    border-right:2px solid #333;
    bottom:0;
    z-index: 97;
}

.guestbookright h2{
    padding-top:15px;
}


.guestbookleft{
    position:relative;

}


.wrappoolclub .cafeteriabg_top{
    width:100%;    
    margin: 0;
    padding:0;
    position: absolute;
    left:0;
    top: 0;
    z-index: 5;
    pointer-events: none;

}

.wrappoolclub .cafeteriabg_bottom{
    width:100%;    
    margin: 0;
    padding:0;
    position: absolute;
    left: 0;
    bottom: -10px;
    z-index: 1;
    pointer-events: none;
}

.buckincafeteria{
    position: absolute;
    bottom: 32%;
    z-index: 2;
    max-width:17%;
    -webkit-animation: talkingCharAni 2.5s ease-in-out infinite;
    animation: talkingCharAni 2.5s ease-in-out infinite;
}

.buckincafeteriaLeft{
    left:22%;
}

.buckincafeteriaRight{
    left:22%;
}

.guestbookentries{
    overflow-y: scroll;
    max-height:calc(100% - 140px);
    padding:15px;
    overflow-x:hidden;
}

.golemGuestbook{
    padding:15px;
    width:100%;
    text-align: left;

}

.golemGuestbook .wrapgolem{
    border-bottom:1px solid #000;
}

.golemGuestbook .golemimg{
    max-width:100%;
    margin-right: 30px;
    margin-bottom: 15px;
    border-radius: 15px;
}

.golemGuestbook h2{
    padding:0;
    margin:0;
}

.golemGuestbook h3{
    margin-bottom:2px;
}

.golemGuestbook .aboutline{
    margin-top:10px;
}

.golemGuestbook .copperline{
    height:32px;
    line-height: 32px;
    margin-top:15px;
    vertical-align: middle;
}

.golemGuestbook .coppericon{
    width:32px;
    float: left;
    margin-right:10px;
}

.golemGuestbook .loreline{
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    font-size:14px;
}

.cafeteria_beer{
    position:absolute;
    top: 48%;
    left:56%;
    width:6%;
    height:15%;
    z-index:16;
}

.cafeteria_shelf{
    position:absolute;
    top: 36%;
    left:39%;
    width:18%;
    height:25%;
    z-index:15;
}

.cafeteria_chairs{
    position:absolute;
    bottom:0;
    right:0;;
    width:25%;
    height:32%;
    z-index:15;
}

.cafeteria_logo{
    position:absolute;
    top:15%;
    left:40%;
    width:22%;
    height:20%;
    z-index:15;
}

.cafeteria_cash{
    position:absolute;
    top: 48%;
    left:8%;
    width:8%;
    height:15%;
    z-index:16;
}

.sitGolemz{
    position:absolute;
    bottom:0;
    right:0;;
    width:25%;
    z-index:15;
}

.sitGolemz img{
    max-width:100%;
    position:relative;
    bottom:-50px;
}


/* SHOP */

.bomberinshop{
    position: absolute;
    bottom: 39%;
    z-index: 2;
    max-width:17%;
    -webkit-animation: talkingCharAni 2.5s ease-in-out infinite;
    animation: talkingCharAni 2.5s ease-in-out infinite;
}

.bomberinshopLeft{
    left:18%;
    transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}

.wrappoolclub .bombersshop_top{
    width:100%;    
    margin: 0;
    padding:0;
    position: absolute;
    left:0;
    bottom: 0;
    z-index: 5;
    pointer-events: none;

}

.wrappoolclub .bombersshop_bottom{
    width:100%;    
    margin: 0;
    padding:0;
    position: absolute;
    left: 0;
    bottom: -10px;
    z-index: 1;
    pointer-events: none;
}

.shopweapon{
    position:absolute;
    z-index:10;
}

.shopweapon.axe{
    top: 34%;
    left:39%;
    width:8%;
}

.shopweapon.bomb{
    top: 32.5%;
    left:51%;
    width:4.5%;
}

.shopweapon.gazooka{
    top: 33%;
    left:60%;
    width:8%;
}

.shopweapon.noodle{
    top: 45.5%;
    left:39%;
    width:13%;
}

.shopweapon.nunchaku{
    top: 41%;
    left:55%;
    width:6%;
}

.shopweapon.fork{
    top: 47.5%;
    left:62%;
    width:8%;
}

.shopweapon:hover{
    transform: scale(1.1);;
}

.clickVase{
    position:absolute;
    top: 37%;
    left:5%;
    width:8%;
    height:22%;
    z-index:16;
}

.clickFireworks{
    position:absolute;
    top: 80%;
    left:35%;
    width:8%;
    height:18%;
    z-index:16;

}   

.clickSmallbox{
    position:absolute;
    top: 46%;
    left:69%;
    width:8%;
    height:18%;
    z-index:16;

}

.clickTripod{
    position:absolute;
    top: 52%;
    left:80%;
    width:15%;
    height:48%;
    z-index:16;

}

.clickSale{
    position:absolute;
    top: 12%;
    left:35%;
    width:8%;
    height:12%;
    z-index:16;

}

.weaponCard{
    position:relative;
    z-index: 20;
    top:10%;
    transform: rotate(-0.5deg) scale(1);
    transition: all 1s ease-in-out;

}

.weaponCard img{
    max-width:40%;
    position:relative;
    border-radius: 15px;
    box-shadow: 0px 00px 110px 120px rgba(0,0,0,0.4);

}

.weaponhidden{
    transform: rotate(3deg) scale(0);
}
